<template>
  <div class="main_page info_page">
    <xmas-banner-subscription></xmas-banner-subscription>
    <cny-banner-subscription></cny-banner-subscription>
    <main-page-menu></main-page-menu>
    <div class="info_section site_cover">
      <header class="background_header">
        <div class="research-header header-block">
          <video loop muted autoplay class="splash-bg" :poster="$getAsset('/assets/video/mcr-reel.jpg')">
            <source :src="$getAsset('/assets/video/mcr-reel-2021.webm')" type="video/webm" />
          </video>
          <div class="splash-overlay"></div>
          <div class="header-content">
            <h1 v-if="mainTitle">{{ mainTitle }}</h1>
            <div class="main-cta-buttons">
              <mcr-button-router-link
                class="fat"
                :label="researchSiteButtonLabel"
                :to="researchSiteRoute"
                @click="trackClick(researchSiteButtonLabel)"
              ></mcr-button-router-link>
              <mcr-button-router-link
                class="fat"
                :label="appSiteButtonLabel"
                :to="appSiteRoute"
                @click="trackClick(appSiteButtonLabel)"
              ></mcr-button-router-link>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="info_section" id="company_intro">
      <div class="info_section_content">
        <logo-wall></logo-wall>
      </div>
    </div>

    <div class="image_panel">
      <div class="image_panel_text">
        <h4>Join {{ usersCountDisplayState }} users searching on My China Roots</h4>
        <div class="text-block">
          <div>
            Grow your tree, get notified of new collections, and connect with others passionate about exploring their
            Chinese heritage.
          </div>
        </div>
        <mcr-button-router-link :to="getStartedRoute" class="inline">
          <span @click="trackPreRegisterAction">{{ registerLabel }}</span>
        </mcr-button-router-link>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/family-tree-library.png')"
          class="in-layer lazyload"
          width="920"
          height="685"
        />
      </div>
    </div>

    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h4>Hit a brick wall? Ask the experts.</h4>
        <div class="text-block">
          <div>Can't travel to China or speak Chinese?</div>
          <div>
            Our China-based researchers specialize in tracking down ancestral villages and translating family history
            records.
            <router-link :to="{name: 'ancestry'}">Learn More</router-link>
          </div>
        </div>
        <mcr-button-router-link-to-pr label="Get in Touch" class="inline"></mcr-button-router-link-to-pr>
      </div>
      <div class="image_panel_image">
        <img
          :data-src="this.$getAsset('/assets/services/ancestry/IMG_1835web.jpg')"
          class="lazyload"
          width="1200"
          height="900"
        />
      </div>
    </div>

    <database-overview></database-overview>

    <videos-section></videos-section>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import DatabaseOverview from '@common/elements/main-menu/DatabaseOverview';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';

import MainPageMenu from '@/base/elements/main-menu/MainPageMenu';

import LogoWall from '@/components/common/images/LogoWall';
import cnyBannerSubscription from '@/components/common/promotions/cnyBannerSubscription';
import xmasBannerSubscription from '@/components/common/promotions/xmasBannerSubscription';

import VideosSection from '@/components/modules/main/components/VideosSection';

export default {
  metaInfo: {
    title: 'My China Roots – Trace Your Ancestry in China',
    titleTemplate: null,
  },
  data() {
    return {
      registerLabel: 'Create a FREE Account',
      researchSiteButtonLabel: 'Hire Our Experts',
      researchSiteRoute: {name: 'services-main'},
      appSiteButtonLabel: 'Search for Records',
      appSiteRoute: {name: 'platform-main'},
    };
  },
  computed: {
    usersCountDisplayState() {
      return this.$store.getters.usersCountDisplayState;
    },
    getStartedRoute() {
      const route = {name: 'get-started'};
      if (this.$store.getters.userIsLoggedInState) {
        return route;
      }
      const getStartedPage = this.$router.resolve(route).href;
      return {name: 'register', query: {redirect: getStartedPage}};
    },
    mainTitle() {
      return 'Connect with your roots, your family and yourself';
    },
  },
  methods: {
    trackPreRegisterAction() {
      if (this.$store.getters.userIsLoggedInState) {
        return;
      }
      AnalyticsAmplitudeHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
    trackClick(label) {
      AnalyticsAmplitudeHandler.trackClickButton(
        label,
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route)
      );
    },
  },
  components: {
    LogoWall,
    MainPageMenu,
    DatabaseOverview,
    VideosSection,
    McrButtonRouterLink,
    McrButtonRouterLinkToPr,
    xmasBannerSubscription,
    cnyBannerSubscription,
  },
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins';
@import '@common/style/info.page';

.xmas-banner-subscription,
.cny-banner-subscription {
  @media only screen and (min-width: $main-menu-breakpoint) {
    + .main-page-menu {
      top: 100px;
    }
  }
  @media only screen and (max-width: $main-menu-breakpoint) {
    top: $main-menu-height-mobile;
  }
}

.image_panel {
  align-items: center;

  .mcr-button {
    margin-bottom: 28px;
  }
  @media only screen and (min-width: $breakpoint-tablet) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.site_cover {
  background-color: #222;
  justify-content: center;

  header {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: transparent;
    min-height: calc(100vh - 200px);
    max-height: 1080px;
    height: auto;
    padding: 0;
    width: 100%;

    .header-block {
      z-index: 3;
      flex-basis: 0;
      flex-grow: 1;
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 3;
        padding: 100px $desktop-content-padding 50px;
        min-height: 250px;

        .main-cta-buttons {
          margin-top: 40px;
          display: flex;
          .mcr-button:first-child {
            margin-right: 50px;
          }
        }
      }
      .splash-bg {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: 1;
      }

      .splash-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.4);
        z-index: 2;

        @media only screen and (min-width: $main-menu-breakpoint) {
          -webkit-box-shadow: inset 0 0 120px rgba(black, 0.5);
          box-shadow: inset 0 0 120px rgba(black, 0.5);
          background-image: linear-gradient(180deg, rgba(black, 0.3), rgba(black, 0.3) 15px, rgba(black, 0));
          background-repeat: no-repeat;
          background-size: 100% 200px;
        }
      }
    }

    h1 {
      text-align: center;
      font-size: 42px;
      span {
        display: inline-block;
      }
    }

    @media only screen and (max-width: $breakpoint-desktop) {
      h1 {
        font-size: 38px;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: calc(100vh - 150px);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header-block {
        flex-basis: auto;
        height: auto;
        width: 100%;
        .header-content {
          margin-top: 0;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile 70px 0;

          .main-cta-buttons {
            flex-direction: column;
            .mcr-button {
              margin-right: 0;
              margin-bottom: 20px;
              width: 100%;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-phablet) {
      h1 {
        font-size: 36px;
      }
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile 50px 0;
        }
      }
    }
    @media only screen and (max-width: $breakpoint-mobile-se) {
      .header-block {
        .header-content {
          padding: $main-menu-height-mobile $mobile-se-content-padding 0;
          justify-content: flex-start;
        }
      }
      h1 {
        font-size: 32px;
      }
    }

    @media only screen and (max-width: 350px) {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

#company_intro {
  margin-bottom: 20px;
}

.statements {
  max-width: $form-max-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info_page .info_section .info_section_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;

  p {
    text-align: center;
  }

  a {
    margin-bottom: 24px;
  }

  .separator {
    margin: 0 5px;
  }
}
</style>
